import React from 'react';
import {makeStyles} from '@material-ui/core/styles';
import Stepper from '@material-ui/core/Stepper';
import Step from '@material-ui/core/Step';
import StepLabel from '@material-ui/core/StepLabel';
import {
    ColorlibConnector,
    ColorlibStepIcon,
    ContainerPaper,
} from "./Styling";

const Instructions = ({config, props}) => {
    const classes = useStyles();

    return (
        <React.Fragment>
            <ContainerPaper elevation={0}>

                <Stepper className={classes.stepper} alternativeLabel activeStep={2} connector={<ColorlibConnector/>}>
                    <Step>
                        <StepLabel StepIconComponent={ColorlibStepIcon} ><p className={classes.step}>Step 1</p> <p className={classes.Instructions}>Please send <em className={classes.highlight}><b>Hi</b></em> to <em className={classes.highlight}><b>{process.env.REACT_APP_WHATSAPP_NUMBER}</b></em> on WhatsApp to access the clock-in tool.</p></StepLabel>
                    </Step>
                    <Step>
                        <StepLabel StepIconComponent={ColorlibStepIcon} ><p className={classes.step}>Step 2</p> <p className={classes.Instructions}>First time users will be required to register before they can complete their clock-in.</p></StepLabel>
                    </Step>
                    <Step>
                        <StepLabel StepIconComponent={ColorlibStepIcon} ><p className={classes.step}>Step 3</p> <p className={classes.Instructions}>When prompted, please enter the code to complete your clock-in for the day.</p></StepLabel>
                    </Step>

                </Stepper>
            </ContainerPaper>
        </React.Fragment>
    )
}

const useStyles = makeStyles(theme => ({
    stepper: {
        padding: '0px 0px 0px 0px'
    },
    buttonContainer: {
        display: 'flex',
        justifyContent: 'flex-center',
        marginTop: theme.spacing(0)
    },
    title: {
        color: theme.palette.primary.main,
        fontSize: '16px',
        fontWeight: 'bold'
    },
    smsText: {
        padding: '5px',
        backgroundColor: '#f8f3f3'
    },
    highlight: {
        color: "#1d3544",
        fontStyle: "Normal",
        fontSize: "22px",
        // fontFamily: "Roboto",
        fontWeight: "bold",
    },
    modal: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    },
    paper: {
        backgroundColor: theme.palette.background.paper,
        boxShadow: theme.shadows[5],
        padding: theme.spacing(4, 0, 6),
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center'
    },
    printAcknowledgement: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        cursor: 'pointer'
    },
    divider: {
        width: '65%'
    },
    checkIcon: {
        color: theme.palette.success.main
    },
    Instructions: {
        color: "#000000",
        // fontStyle: "Normal",
        fontSize: "17px",
        // fontFamily: "Roboto",
        // fontWeight: "bold",
    },
    step: {
        color: "#8a8989",
        // fontStyle: "Normal",
        fontSize: "17px",
        // fontWeight: "bold",
        // fontFamily: "Roboto",
    }
}));


export default Instructions;