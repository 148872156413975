import React, { useEffect, useState, useContext } from 'react';
import { useParams } from 'react-router-dom';
import Recycle, { getObjectByPath } from '../Recycle';
import { AppConfigContext } from '../App';
import IconButton from '@material-ui/core/IconButton';
import RefreshIcon from '@material-ui/icons/Refresh';
import { makeStyles } from '@material-ui/core/styles';

import Facility from './Facility';
import Paper, { PaperContainer } from './Paper';
import Row from './Row';
import Column from './Column';
import Divider from './Divider';
import Greetings from './Greetings';

export const widgets = {};
const registerWidget = (name, widget) => {
    widgets[name] = widget;
}

registerWidget('facility', Facility);
registerWidget('paper', Paper);
registerWidget('paper-container', PaperContainer);
registerWidget('row', Row);
registerWidget('column', Column);
registerWidget('divider', Divider);
registerWidget('greetings', Greetings);

const Widget = React.memo(({ config, props }) => {
    const pathParams = useParams();
    const [widgetProps, setWidgetProps] = useState(null);
    const [initialized, setInitialized] = useState(false);
    const appConfig = useContext(AppConfigContext);
    const classes = useStyles();

    useEffect(() => {
        initialize(true);

        if (config.data_poll_in_seconds) {
            const interval = setInterval(() => {
                refresh();
            }, config.data_poll_in_seconds);

            return (() => {
                clearInterval(interval);
            });
        }
    }, []);


    useEffect(() => {
        if (widgetProps) {
            setInitialized(true);
        }
    }, [widgetProps]);

    const initialize = async (showLoader = false) => {
        const data = await getData(showLoader);

        const newProps = {};

        config.props.forEach(prop => {
            const propType = prop.type || prop.propType;

            if (propType && propType === 'appConfig') {
                newProps[prop.dest] = getObjectByPath(prop.source, appConfig)
            } else if (propType && propType === 'pathParam') {
                newProps[prop.dest] = pathParams[prop.pathKey];
            } else if (propType && propType === 'static') {
                newProps[prop.dest] = prop.value;
            } else {
                newProps[prop.dest] = getObjectByPath(prop.source, { ...props, ...data });
            }
        });

        setWidgetProps(newProps);
    }

    const getData = async (showLoader) => {
        let result = {};

        if (config.data) {
            await Promise.all(config.data.map(async (source) => {
                const args = [];

                if (source.args) {
                    source.args.forEach((arg) => {
                        if (arg.type === 'static') {
                            args.push(arg.value);
                        } else if (arg.type === 'path_param') {
                            args.push(pathParams[arg.key]);
                        } else if (arg.type === 'appConfig') {
                            const configArg = getObjectByPath(arg.key, appConfig);
                            args.push(configArg);
                        }
                    });
                }

                result[source.key] = await Recycle.rpc({
                    model: source.model,
                    method: source.method,
                    args: args,
                    kwargs: source.kwargs
                }, showLoader).catch(err => {
                    return null;
                })
            }));
        }

        return result;
    }

    const refresh = (showLoader = false) => {
        initialize(showLoader);
    }

    const widget = widgets[config.type];

    if (!widget) return <h1>No widget found: {config.type}</h1>;

    return (
        <div className={classes.container}>
            {config.refreshable ?
                <IconButton className={classes.refreshButton} onClick={() => refresh(true)}>
                    <RefreshIcon />
                </IconButton> : null
            }
            {initialized ? React.createElement(widget, { config, props: widgetProps, refresh }) : null}
        </div>
    )
});

const useStyles = makeStyles((theme) => ({
    container: {
        position: 'relative'
    },
    refreshButton: {
        position: 'absolute',
        top: 0,
        right: 0,
        zIndex: 10
    }
}));

export default Widget;