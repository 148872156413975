import React from "react";
import * as OTPAuth from 'otpauth';
import {makeStyles} from "@material-ui/core/styles";

const TOTP = (token_configs) => {
    const classes = useStyles();

    let totp = new OTPAuth.TOTP({
        algorithm: 'SHA256',
        digits: token_configs.digits,
        period: token_configs.interval,
        secret: token_configs.secret
    });

    let token = totp.generate();

    return (
        <div className={classes.facility_code}>
            {`${token_configs.facility_code}${token}`}
        </div>
    )
};

const useStyles = makeStyles((theme) => ({
    otp: {
        // paddingRight: "100px"
    },
    facility_code: {
        color: "#1d3544",
        fontStyle: "Normal",
        fontSize: "4.5vw",
        // fontFamily: "Roboto",
        fontWeight: "bold",
        paddingLeft: "2vw"
    },
}));

export default TOTP;