import React, {useState} from 'react';
import Recycle from '../../Recycle';
import {makeStyles} from "@material-ui/core/styles";
import TOTP from "../TOTP"
import Box from '@material-ui/core/Box';
import TextField from '@material-ui/core/TextField';
import Autocomplete from '@material-ui/lab/Autocomplete';
import TimeRemaining from "../utils";
import Instructions from "../Instructions";
import Paper from '@material-ui/core/Paper';


const Facility = ({config, props, refresh}) => {
    const classes = useStyles();
    const [seconds, setSeconds] = useState(null);
    const [period, setPeriod] = useState(Number(props.facility?.interval) ? props.facility.interval : 0);
    const [facility, setFacility] = useState((props.facility === "not found" || props.facility === null) ? null : props.facility);
    const [facilityList, setFacilityList] = useState([]);

    React.useEffect(() => {
        const interval = setInterval(() => {
            setSeconds(TimeRemaining(period) + 1)
            setSeconds(seconds => seconds - 1)
        }, 1000);
        return () => clearInterval(interval);
    }, [period])

    React.useEffect(() => {
        Recycle.rpc({
            model: "FacilityController",
            method: "get_all_facilities",
            args: []
        }).then((res) => {
            setFacilityList(res);
        });
    }, [])

    const handleFacilityChange = (facility_code) => {
        if (facility_code) {
            Recycle.rpc({
                model: "FacilityController",
                method: "get_facility_otp_token",
                args: [facility_code]
            }).then((res) => {
                setPeriod(Number(res?.interval) ? res.interval : 0)
                setFacility((res === "not found" || res === null) ? null : res)
            });
        } else {
            setPeriod(0)
            setFacility(null)
        }
    }

    return (
        <Paper className={classes.mainPaper}>
            <div className={classes.imageContainer}><img src="/search.png" alt="search"/></div>
            <div className={classes.formContainer}>
                {facility ? null :
                    <Box display="flex" flexDirection="column" alignItems="" justifyContent="center"
                         bgcolor="background.paper">
                        <div className={classes.welcome_text}>
                            <p>Please select a site from the dropdown below</p>
                        </div>
                        <Box p={1}>
                            <Autocomplete
                                id="Facility"
                                options={facilityList}
                                onChange={(event, newValue) => {
                                    handleFacilityChange(newValue?.code);
                                }}
                                getOptionSelected={(option, value) => option.code === value.code}
                                getOptionLabel={option => option.name}
                                style={{width: 300}}
                                renderInput={(params) => <TextField {...params} label="Select Facility"
                                                                    variant="outlined"/>}
                            />
                        </Box>
                    </Box>}
                {
                    facility ?
                        <Box alignItems="center" display="flex" p={1} bgcolor="background.paper">
                            <Box width="60vw" alignItems="center">
                                <p className={classes.code}>Please see the code below for {facility.facility_name}</p>
                                <Box display="flex" flexDirection="column">
                                    <Box alignItems="flex-end" display="flex" flexDirection="row">
                                        <Box className={classes.facility}>
                                            {(seconds % 2 === 0) ? <TOTP {...facility}/> : null}
                                            {(seconds % 2 === 1) ? <TOTP {...facility}/> : null}
                                        </Box>
                                        <Box width="100px" className={classes.change_site}>
                                            <p className={classes.changeSite} onClick={() => setFacility(null)}>Change site</p>
                                        </Box>
                                    </Box>
                                </Box>
                                <p className={classes.code}>Code expires in {seconds} seconds</p>
                            </Box>
                            <Box>
                                <p className={classes.title_employee}>How to use the employee clock-in tool</p>
                                <Box display="flex" flexDirection="column" alignItems="center" justifyContent="center"
                                     m={1}
                                     bgcolor="background.paper">
                                    <Box className={classes.text} p={1}>
                                        <Instructions/>
                                    </Box>
                                </Box>
                            </Box>
                        </Box> : null
                }

            </div>
        </Paper>
    )
};

const useStyles = makeStyles((theme) => ({
    formContainer: {
        padding: theme.spacing(3)
    },
    imageContainer: {
        display: 'flex',
        justifyContent: 'center',
        backgroundColor: '#EBEEF0'
    },
    code: {
        margin: "0px 0px 0px 0px",
        paddingLeft: "2vw",
        fontSize: "20px",
    },
    text: {
        color: "#1D3440",
        fontStyle: "Normal",
        fontSize: "23px",
        // fontFamily: "Roboto",
        fontWeight: "bold",
    },
    highlight: {
        color: "#be6262",
        fontStyle: "Normal",
        fontSize: "30px",
        // fontFamily: "Roboto",
        fontWeight: "bold",
    },
    divider: {
        background: "grey",
    },
    title_employee: {
        color: "#000000",
        fontStyle: "Normal",
        fontSize: "20px",
        // fontFamily: "Roboto",
        // fontWeight: "bold",
        textAlign: "center",
        marginBottom: "0px"
    },
    welcome_text: {
        color: "black",
        fontSize: "20px",
        flex: 10,
        textAlign: "left",
    },
    margin: {
        paddingBottom: "50px"
    },
    mainPaper: {
        height: "78vh"
    },
    changeSite: {
        color: "#1d3544",
        cursor: "pointer",
        marginLeft: "5px",
        fontSize: "0.9vw",
    }
}));

export default Facility;