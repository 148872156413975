import React, { useContext } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import SunIcon from '@material-ui/icons/WbSunny';
import { AppConfigContext } from '../App';
import { sharedStyles } from '../shared/styles';
import clsx from 'clsx';

const Greetings = ({ config, props }) => {
    const appConfig = useContext(AppConfigContext);
    const sharedClasses = sharedStyles();
    const classes = useStyles();

    return (
        <div className={clsx([classes.container, sharedClasses.heading])}>
            <SunIcon className={classes.icon} />
            <div className={classes.hello}>Hello,</div>
            <div className={classes.username}>{appConfig.user.name}</div>
        </div>
    )
}

const useStyles = makeStyles((theme) => ({
    container: {
        display: 'flex',
        alignItems: 'center'
    },
    icon: {
        color: '#F6BA3B',
        marginRight: theme.spacing(1),
        fontSize: "35px",
        fontFamily: "Roboto",
    },
    hello: {
        fontWeight: 'bold',
        marginRight: theme.spacing(2),
        fontSize: "30px",
    },
    username: {
        marginRight: theme.spacing(2),
        fontSize: "30px",
    }
}));

export default Greetings;