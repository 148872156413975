import { App, Auth0InternalAuthZ  } from 'recycle-frontend-core';

export { interpolate, getObjectByPath, evaluatePolishExpression } from 'recycle-frontend-core'

const authProvider = new Auth0InternalAuthZ({
    grant_type: 'password',
    audience: "https://services.netcare.co.za",
    scope: 'openid',
    client_id: "0fCnazhXmlap7tPjzG6v6AXffBFdyZuz",
    endpoint: 'https://netcareplus.eu.auth0.com',
    connection: 'Username-Password-Authentication',
    authorization_endpoint: `${process.env.REACT_APP_API_ENDPOINT}/authorize`
});

const fetchConfig = {
    routes: () => null
}
const Recycle = new App(
    authProvider,
    process.env.REACT_APP_API_ENDPOINT,
    fetchConfig,
);
export default Recycle;